import React from "react"
import Icon from "./icons/error.inline.svg"

const Error = () => (
  <section data-testid='error-page' className='py4 center'>
    <img src={Icon} alt='Error!' style={{ width: "150px", marginBottom: "-1rem" }}/>
    <h3 className='mb0'>Error</h3>
    <p className='mt0'>Contact support if this problem continues</p>
  </section>
)

export default Error
