import React, { useState } from "react"
import ReactTooltip from "react-tooltip"
import { Radio, Checkbox } from "@bitcine/cs-theme"
import { FILTER_OPTIONS, SORT_OPTIONS } from "@constants"
import Filter from "../filter"

const SearchAndFilter = ({ sort, setSort, search, setSearch, activeFilter, setActiveFilter, toggleAll, filters, toggleFilters }) => {
  const [input, setInput] = useState(null)
  return (
    <div className='flex items-center'>
      <span
        onClick={() => input && input.focus()}
        style={{ marginRight: "-2rem" }}
        className='white material-icons cursor body-text-font-color'>
        search
      </span>
      <input
        value={search}
        data-testid='room-search'
        ref={ref => setInput(ref)}
        style={{ maxWidth: "280px", paddingLeft: "2.5rem" }}
        onChange={e => setSearch(e.target.value)}
        placeholder='Search screeners...'
        className='col-12 white-input small-input'/>
      {search.length > 0 && (
        <>
          <ReactTooltip
            id={'clear-search-tip'}
            place="bottom"
            effect="solid"
            className="bold"/>
          <button
            data-tip={`Clear Search`}
            data-for={'clear-search-tip'}
            style={{ width: "40px", height: "40px", borderRadius: "50%" }}
            className="cs-button link pointer material-icons"
            onClick={() => setSearch("")}>
            <span className='white body-text-font-color'>close</span>
          </button>
        </>
      )}
      <div className='relative mx1'>
        <button
          onClick={() => setActiveFilter(activeFilter === "sort" ? "" : "sort")}
          style={{ width: "40px", height: "40px", borderRadius: "50%" }}
          className={`link material-icons ${activeFilter === "sort" ? 'activeButton' : ""}`}>
          <span className={`${activeFilter === "sort" ? "" : "white"} body-text-font-color`}>
            sort_by_alpha
          </span>
        </button>
        <Filter
          showScroll={false}
          close={() => setActiveFilter("")}
          open={activeFilter === "sort"}>
          <div>
            {SORT_OPTIONS.map(opt => (
              <label
                key={opt.label}
                className={`flex items-center pl2 pr4 py1 nowrap filterRow pointer`}>
                <Radio
                  checked={sort.by === opt.key && sort.direction === opt.direction}
                  onChange={() => setSort({ by: opt.key, direction: opt.direction })}/>
                <div className='pl2'>{opt.label}</div>
              </label>
            ))}
          </div>
        </Filter>
      </div>
      <div className='relative'>
        <button
          data-testid='room-filter-btn'
          onClick={() => setActiveFilter(activeFilter === "filter" ? "" : "filter")}
          style={{ width: "40px", height: "40px", borderRadius: "50%" }}
          className={`link material-icons ${activeFilter === "filter" ? 'activeButton' : ""}`}>
          <span className={`${activeFilter === "filter" ? "" : "white"} body-text-font-color`}>
            filter_list
          </span>
        </button>
        <Filter close={() => setActiveFilter("")} open={activeFilter === "filter"}>
          <div>
            <div className='border-bottom border-gray-5 mb1 pb1'>
              <label className={`flex items-center pl2 pr4 py1 nowrap filterRow pointer`}>
                <Checkbox checked={filters.length === 0} onChange={() => toggleAll()}/>
                <div className='pl2'>All Genres</div>
              </label>
            </div>
            {FILTER_OPTIONS.map(opt => (
              <label
                key={opt}
                data-testid={`room-filter-${opt.toLowerCase()}`}
                className={`flex items-center pl2 pr4 py1 nowrap filterRow pointer`}>
                <Checkbox checked={filters.includes(opt)} onChange={() => toggleFilters(opt)}/>
                <div className='pl2'>{opt}</div>
              </label>
            ))}
          </div>
        </Filter>
      </div>
    </div>
  )
}

export default SearchAndFilter
