import React from "react"
import PropTypes from "prop-types"

const PlaybackError = ({ children, error }) =>
  !error ? (
    children
  ) : (
    <section style={{ height: '100vh' }} className='flex items-center justify-center'>
      <div className='center'>
        <div className='material-icons' style={{ fontSize: '64px' }}>movie</div>
        <h3>Playback Error</h3>
        <p className='my1'>
          A playback error occurred.
          <br/>
          Please contact CineSend support if this problem persists.
        </p>
      </div>
    </section>
  )

PlaybackError.propTypes = {
  children: PropTypes.node.isRequired,
  error: PropTypes.bool.isRequired
}

export default PlaybackError
