import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import styled from "@emotion/styled"
import Info from "./info"

const Sidebar = styled.div`
  position: sticky;
  top: 0;
  height: 100vh;
  width: 244px;
  max-width: 244px;
`

const Aside = ({ organization }) => (
  <Sidebar className='p3 xs-hide sm-hide md-block lg-block xl-block sidebar-background-color'>
    <Info/>
  </Sidebar>
)

const mapStateToProps = state => ({
  organization: state.organization
})

Aside.propTypes = {
  organization: PropTypes.object.isRequired
}

export default connect(
  mapStateToProps,
  null
)(Aside)
