import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { ReactComponent as Logo } from "@src/public/cinesend-gray.inline.svg"
import styled from "@emotion/styled"

const FooterDiv = styled.div`
  position: absolute;
  width: 100%;
  opacity: 35%;
  bottom: 0;
  height: 65px;
`

class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      client: false
    }
  }
  componentDidMount() {
    this.setState({ client: true })
  }
  render() {
    const organization = this.state.client ? this.props.organization : null
    return organization && !!organization.branding ? (
      <FooterDiv className='flex justify-center'>
        <div className='pb2'>
          <div className='flex justify-center'>
            <h5 className='muted body-text'>Powered By</h5>
          </div>
          <div className='flex justify-center'>
            <a href='https://cinesend.com'>
              <Logo style={{ width: "100px" }}/>
            </a>
          </div>
        </div>
      </FooterDiv>
    ) : null
  }
}

Footer.propTypes = {
  organization: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  organization: state.organization
})

export default connect(mapStateToProps)(Footer)
