import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
//import Logo from "@src/public/cinesend-white.inline.svg"
import Info from "../organization/info"
import styled from "@emotion/styled"

const NavContainer = styled.div`
  opacity: 0;
  width: 0px;
  height: 0px;
  right: -100%;
  transition: right .3s linear;
  .open-nav {
    right: 0px;
    display: flex;
    height: 100%;
    width: 100%;
    opacity: 1;
    transition: right .3s linear;
    max-width: 384px;
  }
`


class Nav extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      client: false
    }
    this.open = this.open.bind(this)
    this.close = this.close.bind(this)
  }
  componentDidMount() {
    this.setState({ client: true })
  }
  open() {
    this.setState({ open: true })
    document.body.style.overflow = "hidden"
  }
  close() {
    this.setState({ open: false })
    document.body.style.overflow = "scroll"
  }
  render() {
    const organization = this.state.client ? this.props.organization : null
    return organization && !!organization.branding ? null : (
      <header
        style={{ background: "rgba(32, 38, 59, 0.24)" }}
        className='flex items-center justify-between py2 px3 md-hide lg-hide xl-hide'>
        {/*<Logo alt='CineSend' style={{ width: "100px" }}/>*/}
        <div className='flex'>
          <button data-testid='mobile-menu-open-btn' onClick={this.open} className='cs-button link material-icons'>
            <span className='white'>menu</span>
          </button>
          <NavContainer
            data-testid='mobile-menu-wrap'
            className={`${this.state.open ? 'open-nav' : ""} fixed top-0 z4 pl2 items-stretch`}>
            <div
              style={{
                background: "linear-gradient(134.64deg, #202632 0%, #3B4457 100%)",
                boxShadow: "-8px 0 15px 0 rgba(0,0,0,0.5)"
              }}
              className='overflow-auto col-12 pl2 py3 pr4'>
              <button
                data-testid='mobile-menu-close-btn'
                onClick={this.close}
                className='cs-button absolute top-0 right-0 material-icons link mr1 mt1'>
                <span className='white'>close</span>
              </button>
              <Info/>
            </div>
          </NavContainer>
        </div>
      </header>
    )
  }
}

Nav.propTypes = {
  organization: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  organization: state.organization
})

export default connect(mapStateToProps)(Nav)
