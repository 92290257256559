import React from "react"
import { ReactComponent as Icon } from "./icons/error.inline.svg"

const NotFound = () => (
  <section
    style={{ height: '100vh' }}
    data-testid='404-not-found'
    className='py4 flex items-center justify-center'>
    <div className='center'>
      <Icon style={{ width: "150px", marginBottom: "-1rem" }}/>
      <h2 className='mb0'>404</h2>
      <p className='mt0'>This page or screener does not exist</p>
    </div>
  </section>
)

export default NotFound
