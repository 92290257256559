import { GET_INITIAL_STATE_FULFILLED, CHECK_PASSWORD_FULFILLED } from "@actions"

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case GET_INITIAL_STATE_FULFILLED:
    case CHECK_PASSWORD_FULFILLED:
      return {
        ...action.payload.sharedScreener,
        name: action.payload.name,
        watch_on_tv_message: action.payload.watch_on_tv_message
      }
    default:
      return state
  }
}
