import React from "react"
import { Global, css } from "@emotion/core"
import { connect } from "react-redux"
import PropTypes from "prop-types"

const fallbackFontStack = `'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Helvetica, sans-serif`

const bgColor = branding => {
  if (branding.body_background_color) {
    return css`
      body {
        background: ${branding.body_background_color.hex} !important;
      }
    `
  }

  return css``
}

const sidebarColor = branding => {
  if (branding.sidebar_background_color) {
    return css`
      .sidebar-background-color {
        background-color: ${branding.sidebar_background_color.hex} !important;
      }
    `
  }

  return css``
}

const headerColor = branding => {
  if (branding.header_text_font_color) {
    return css`
      .header-text-color {
        color: ${branding.header_text_font_color.hex} !important;
      }
    `
  }

  return css``
}

const bodyTextColor = branding => {
  if (branding.body_text_font_color) {
    return css`
      .body-text,
      .body-text-font-color,
      input,
      ::placeholder {
        color: ${branding.body_text_font_color.hex} !important;
      }
      input,
      ::placeholder {
        border-color: ${branding.body_text_font_color.hex} !important;
      }
    `
  }

  return css``
}

const sidebarTextColor = branding => {
  if (branding.sidebar_text_font_color) {
    return css`
      .social-link {
        color: ${branding.sidebar_text_font_color.hex} !important;
        fill: ${branding.sidebar_text_font_color.hex} !important;
      }
      .social-link svg g,
      .social-link svg path {
        fill: ${branding.sidebar_text_font_color.hex} !important;
      }
      .sidebar-text-color {
        color: ${branding.sidebar_text_font_color.hex} !important;
      }
    `
  }

  return css``
}

const renderHeaderFonts = branding => {
  const headerFont = branding.fonts.filter(font => font._id === branding.custom_header_font_id)[0] || null
  if (branding.use_custom_font && headerFont) {
    return css`
      @font-face {
        font-family: '${headerFont._id}';
        src: url(${headerFont.url});
      }
      .header-font,h1,h2,h3,h4:not(.ignore),h5,h6 {
        font-family: '${headerFont._id}', ${fallbackFontStack} !important;
      }
    `
  }
  if (branding.header_text_font_family) {
    return css`
      .header-font,h1,h2,h3,h4:not(.ignore),h5,h6 {
        font-family: '${branding.header_text_font_family}', ${fallbackFontStack} !important;
      }
    `
  }
  return css``
}

const renderBodyFonts = branding => {
  const bodyFont = branding.fonts.filter(font => font._id === branding.custom_body_font_id)[0] || null
  if (branding.use_custom_font && bodyFont) {
    return css`
      @font-face {
        font-family: '${bodyFont._id}';
        src: url(${bodyFont.url});
      }
      .body-font,.body-text {
        font-family: '${bodyFont._id}', ${fallbackFontStack} !important;
      }
    `
  }
  if (branding.body_text_font_family) {
    return css`
      .body-font,.body-text {
        font-family: '${branding.body_text_font_family}', ${fallbackFontStack} !important;
      }
    `
  }
  return css``
}

const appendGoogleFontStyleSheet = name => {
  let s = document.createElement("link")
  s.rel = "stylesheet"
  s.href = `https://fonts.googleapis.com/css?family=${name}&display=swap`
  document.getElementsByTagName("head")[0].prepend(s)
}

class CSSInjector extends React.Component {
  componentDidMount() {
    const { branding } = this.props.organization
    if (!branding) {
      return
    }

    if (branding.header_text_font_family) {
      appendGoogleFontStyleSheet(branding.header_text_font_family)
    }

    if (branding.body_text_font_family) {
      appendGoogleFontStyleSheet(branding.body_text_font_family)
    }
  }
  render() {
    const { branding } = this.props.organization
    return !branding ? null : (
      <>
        <Global styles={renderHeaderFonts(branding)}/>
        <Global styles={renderBodyFonts(branding)}/>
        <Global styles={bgColor(branding)}/>
        <Global styles={sidebarColor(branding)}/>
        <Global styles={headerColor(branding)}/>
        <Global styles={bodyTextColor(branding)}/>
        <Global styles={sidebarTextColor(branding)}/>
      </>
    )
  }
}

CSSInjector.propTypes = {
  organization: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  organization: state.organization
})

export default connect(mapStateToProps)(CSSInjector)
