import { GET_INITIAL_STATE_FULFILLED, CHECK_PASSWORD_FULFILLED } from "@actions"

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case GET_INITIAL_STATE_FULFILLED:
    case CHECK_PASSWORD_FULFILLED:
      return {
        ...action.payload.organization
      }
    default:
      return state
  }
}
