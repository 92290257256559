import React from "react"
import PropTypes from "prop-types"
import styles from "./styles.scss"

class Filter extends React.Component {
  constructor(props) {
    super(props)
    this.onClick = this.onClick.bind(this)
    this.close = this.close.bind(this)
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.onClick, false)
    document.addEventListener("touchstart", this.onClick, false)
    window.addEventListener("resize", this.close, false)
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.onClick, false)
    document.removeEventListener("touchstart", this.onClick, false)
    window.removeEventListener("resize", this.close, false)
  }
  close() {
    if (this.props.open) this.props.close()
  }
  onClick(e) {
    if (this.node.contains(e.target) || !this.props.open) return
    this.props.close()
  }
  render() {
    return (
      <div
        ref={ref => (this.node = ref)}
        className={`absolute z4 bg-white rounded box-shadow py1 right-0 top-100 mt1 ${styles.filter} ${
          this.props.open ? "" : "display-none"
        }`}>
        <div style={{ maxHeight: "250px" }} className={this.props.showScroll ? "overflow-auto" : null}>
          {this.props.open ? this.props.children : null}
        </div>
      </div>
    )
  }
}

Filter.defaultProps = {
  showScroll: true
}

Filter.propTypes = {
  children: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  showScroll: PropTypes.bool.isRequired
}

export default Filter
