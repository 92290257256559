import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import bg from "../../public/background_blur.png"
import { hideTerms } from "../../api/utils"
import styled from '@emotion/styled'

const TermsContainer = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-color: #111;
  color: black;
  height: 100vh;
`

const Terms = ({ terms, hideTerms }) => (
  <TermsContainer
    className={`block sm-flex center items-center justify-center overflow-auto`}
    style={{ backgroundImage: `url(${bg})` }}>
    <section className='my4 py4 px3 bg-white max-width-3'>
      <div style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: terms }}/>
      <div className='mt2 right-align'>
        <div className='small muted'>{`By clicking "Enter", you agree to the above terms and conditions.`}</div>
        <button className='cs-button white mt1' onClick={() => hideTerms()}>
          Enter
        </button>
      </div>
    </section>
  </TermsContainer>
)

Terms.propTypes = {
  terms: PropTypes.string.isRequired,
  hideTerms: PropTypes.func
}

function mapStateToProps(state) {
  return {
    terms: state.organization.terms_and_conditions
  }
}

function mapDispatchToProps(dispatch) {
  return {
    hideTerms: bindActionCreators(hideTerms, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Terms)
