import React from "react"
import PropTypes from "prop-types"

const Genres = ({ genres }) => (
  <span className='block truncate body-text' style={{ fontSize: "12px" }}>
    {genres
      ? Array.isArray(genres)
        ? genres.map((genre, index) => <span key={index}>{index === 0 ? `${genre.label}` : ` / ${genre.label}`}</span>)
        : genres
      : ""}
  </span>
)

Genres.propTypes = {
  genres: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
}

export default Genres
