import {
  GET_INITIAL_STATE_FULFILLED,
  CHECK_PASSWORD_FULFILLED,
  CHECK_PASSWORD_PENDING,
  CHECK_PASSWORD_FAILED,
  UPDATE_UTILS,
  HIDE_GATE,
  HIDE_TERMS
} from "@actions"

const initState = {
  show_gate: true,
  show_terms: false,
  checkingPassword: false,
  passwordError: false
}

export default function reducer(state = initState, action = {}) {
  switch (action.type) {
    case GET_INITIAL_STATE_FULFILLED:
      return {
        ...state,
        ...action.payload.utils,
        show_terms: action.payload.organization ? !!action.payload.organization.terms_and_conditions : false,
        debug: action.payload
      }

    case UPDATE_UTILS:
      return {
        ...state,
        [action.key]: action.value
      }

    case HIDE_GATE:
      return {
        ...state,
        show_gate: false
      }

    case HIDE_TERMS:
      return {
        ...state,
        show_terms: false
      }

    case CHECK_PASSWORD_FULFILLED:
      return {
        ...state,
        ...action.payload.utils,
        show_gate: false,
        checkingPassword: false
      }

    case CHECK_PASSWORD_FAILED:
      return {
        ...state,
        passwordError: true,
        checkingPassword: false
      }

    case CHECK_PASSWORD_PENDING:
      return {
        ...state,
        passwordError: false,
        checkingPassword: true
      }

    default:
      return state
  }
}
