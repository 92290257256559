import React from 'react'
import PropTypes from 'prop-types'

class Poster extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      loaded: false,
      error: false,
    }
    this.ready = false
  }

  componentDidMount () {
    this.image = new Image()
    this.image.src = `${this.props.src}`
    this.image.onload = () => this.setState({ loaded: true })
    this.image.onerror = () => this.setState({ error: true })
  }

  render () {
    const { error, loaded } = this.state
    const { src } = this.props
    return (
      <div
        style={{ paddingTop: '144%', boxShadow: '0 6px 21px 0 #1b1e2266' }}
        className={`col-12 rounded relative overflow-hidden ${src
          ? ''
          : 'bg-white'}`}>
        {!src ? (
          <div
            className="center flex items-center justify-center absolute top-0 left-0 right-0 bottom-0">
            <div className="center muted">
              <span style={{ fontSize: '44px', color: '#424A50' }}
                    className="material-icons block">
                photo
              </span>
              <strong style={{ color: '#424A50' }} className="muted block">
                No Poster
              </strong>
            </div>
          </div>
        ) : !loaded ? null : error ? null : (
          <div
            style={{
              backgroundImage: `url(${src})`,
            }}
            className={`absolute top-0 left-0 right-0 bottom-0 rounded bg-cover bg-center`}/>
        )}
      </div>
    )
  }
}

Poster.propTypes = {
  src: PropTypes.string,
  active: PropTypes.bool.isRequired,
}

export default Poster
