import React from "react"
import { ReactComponent as Facebook } from "@src/public/social/facebook.inline.svg"
import { ReactComponent as Twitter } from "@src/public/social/twitter.inline.svg"
import { ReactComponent as Instagram } from "@src/public/social/instagram.inline.svg"

const style = { width: "22px", height: "22px" }
export const showSocialFeed = org => ({
  show: org.twitter_url || org.facebook_url || org.instagram_url,
  list: [
    {
      icon: <Facebook style={style}/>,
      to: org.facebook_url
    },
    {
      icon: <Twitter style={style}/>,
      to: org.twitter_url
    },
    {
      icon: <Instagram style={style}/>,
      to: org.instagram_url
    }
  ].filter(opt => !!opt.to)
})

//////////////////////////////////////////
//////////// Fetch Defaults //////////////
//////////////////////////////////////////

export const DEFAULTS = {
  credentials: "same-origin",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
}

export const POST_DEFAULTS = {
  ...DEFAULTS,
  method: "POST"
}

//////////////////////////////////////////
/////////// Available Sorting ////////////
//////////////////////////////////////////

export const SORT_OPTIONS = [
  { key: "title", direction: "DESC", label: "AZ ↓" },
  { key: "title", direction: "ASC", label: "ZA ↑" }
]

//////////////////////////////////////////
/////// Available Filters (Genres) ///////
//////////////////////////////////////////

export const FILTER_OPTIONS = [
  "Action",
  "Adventure",
  "Animation",
  "Biography",
  "Comedy",
  "Crime",
  "Documentary",
  "Drama",
  "Family",
  "Fantasy",
  "History",
  "Horror",
  "Music",
  "Musical",
  "Mystery",
  "Romance",
  "Scifi",
  "Sport",
  "Thriller",
  "War",
  "Western"
]
