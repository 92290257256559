import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import styled from "styled-components"

const Wrapper = styled.div`
  .playWrap {
    transition: transform .3s ease-in;
    padding-top: 56.25% !important;

    .playIcon {
      transition: transform .3s ease-in;
      font-size: 34px;
      color: white !important;
    }
    &:after {
      background: rgba(0,0,0,0.3);
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    &:hover {
      transform: scale(0.95);
      .playIcon {
        transform: scale(1.3);
      }
    }
  }
  .lockWrap {
    padding-top: 56.25% !important;
    &:after {
      background: rgba(0,0,0,0.7);
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  .lockIcon {
    font-size: 34px;
    color: gray !important;
  }
  .bg-fade {
    background-color: rgba(0,0,0,0.5);
  }
`

const Thumbnail = ({ project, screener, dateAccessible }) => {
  let accessible = dateAccessible
  if (screener.view_count_total && dateAccessible) {
    accessible = screener.view_count_remaining > 0
  }
  const backgroundImage = screener.thumbnail_url_md && screener.thumbnail_url_md.length
    ? `url(${screener.thumbnail_url_md})`
    : "" 
  return (
    <Wrapper className='pr1 pb1'>
      {accessible ? <Link
        data-testid={`room-screener-${screener._id}-info-link`}
        className={`relative col-12 overflow-hidden flex items-center justify-center rounded box-shadow bg-gray-4 bg-center bg-cover playWrap`}
        style={{ backgroundImage }}
        to={`/view/${project.shared_screener_id}?watch=${screener._id}`}>
        {!!screener.view_count_total && <div className='absolute top-0 left-0 bg-fade rounded'>
          <div className='p1 white small bold z4 relative'>{`${screener.view_count_remaining}/${screener.view_count_total} view${screener.view_count_remaining === 1 ? '' : 's'} remaining`}</div>
        </div>}
        <div className='absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center'>
          <span className={`white material-icons z4 relative playIcon`}>
            play_circle_filled
          </span>
        </div>
      </Link> :
      <div
        className={`relative col-12 overflow-hidden flex items-center justify-center rounded box-shadow bg-gray-4 bg-center bg-cover lockWrap`}
        style={{ backgroundImage }}>
        <div className='absolute top-0 left-0 bg-fade rounded'>
          <div className='p1 red small bold z4 relative'>{dateAccessible ? 'No views remaining' : 'Content unavailable'}</div>
        </div>
        <div className='absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center'>
          <span className={`muted white material-icons z4 relative lockIcon`}>
            block
          </span>
        </div>
      </div>}
      <div style={{ fontSize: "12px" }} className='body-font truncate'>
        <b>{screener.name}</b>
      </div>
    </Wrapper>
  )
}

Thumbnail.propTypes = {
  screener: PropTypes.object.isRequired
}

export default Thumbnail