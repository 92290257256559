import React, { useEffect } from 'react'
import Routes from './routes'
import styled from '@emotion/styled'
import '@bitcine/cs-theme/dist/index.scss'

const Wrap = styled.div`
  align-items: center;
  justify-content: center; 
  min-height: 100vh;
  color: #fff;
`

const App = () => {
  useEffect(() => {
    console.log(`%c*************************`, `color: #fb0f3b; font-weight: bold; font-size: 24px;`)
    console.log(`%c*  Powered by CineSend  *`, `color: #fb0f3b; font-weight: bold; font-size: 24px;`)
    console.log(`%c*    Built in Canada    *`, `color: #fb0f3b; font-weight: bold; font-size: 24px;`)
    console.log(`%c*************************`, `color: #fb0f3b; font-weight: bold; font-size: 24px;`)
  }, [])
  return (
    <Wrap>
      <Routes/>
    </Wrap>
  )
}

export default App
