import React from "react"
import PropTypes from "prop-types"
import styled from "react-emotion"

const StyledIcon = styled("div")`
  font-size: 64px;
`

const Icon = ({ icon }) => <StyledIcon className='material-icons block center'>{icon}</StyledIcon>

Icon.propTypes = {
  icon: PropTypes.string.isRequired
}

export default Icon
