import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import styled from "@emotion/styled"

const HeaderContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  background-color: black;
  color: white;
  font-weight: bold;
`

const Header = ({ watchOnTVMessage }) =>
  !watchOnTVMessage ? null :
  <HeaderContainer className='flex justify-center items-center p1'>
    <span className='material-icons green mr2'>ondemand_video</span>{watchOnTVMessage}    
  </HeaderContainer>

Header.propTypes = {
  watchOnTVMessage: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  watchOnTVMessage: state.sharedScreener.watch_on_tv_message
})

export default connect(mapStateToProps)(Header)