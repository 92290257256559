import React, { useState } from "react"
import { DEFAULTS } from "@constants"
import { GLOBALS } from '../../config'

const RequestExtension = ({ isExpired, sharedScreenerID }) => {
  const [pending, setPending] = useState(false)
  const [requested, setRequested] = useState(null)
  return (
    isExpired ?
      <span
        onClick={() => {
          setPending(true)
          fetch(
            `${GLOBALS.BASE_API}sharedScreeners/${sharedScreenerID}/extension`,
            { ...DEFAULTS, method: "POST" })
            .then(res => res.json())
            .then(res => {
              setPending(false)
              setRequested(true)
            })
        }}
        disabled={pending || requested}
        className={`pointer italic underline white mt2 ml1 muted`}>
        {requested ? "Request Sent!" : "Request Extension"}
      </span> : null
  )
}

export default RequestExtension