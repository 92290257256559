import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { showSocialFeed } from "@constants"
import styled from "@emotion/styled"

const Wrap = styled.div`
  .hover {
    display: none;
    opacity: 0;
    background: rgba(255,255,255,0.95);
    transition: opacity .3s linear;
  }
  &:hover {
    .hover {
      display: flex;
      opacity: 1;
    }
  }
  .link-wrap {
    cursor: pointer;
    .link {
      transition: transform .2s ease-in-out;
      border-radius: 3px;  
      &:focus {
        // box-shadow: 0 0 3pt 2pt rgba(255, 255, 255, 0.4)
      }
    }
    &:hover {
      .link {
        transform: scale(.9);
      }
  }
  }
`

const parseURL = url => {
  return "//" + url.replace("https://", "").replace("http://", "")
}

const Info = ({ organization, preview }) => (
  <Wrap className={`relative sidebar-text-color`}>
    {!!organization.branding ? (
      <div className='flex items-center justify-center mb2'>
        <img
          data-testid='org-logo'
          src={organization.branding.screener_logo_url}
          className='block col-12'
          alt={organization.name}/>
      </div>
    ) : (
      <>
        {organization.logo_url && (
          <div
            style={{ width: "110px", height: "110px" }}
            className='bg-white rounded p1 flex items-center justify-center mb2'>
            <img data-testid='org-logo' src={organization.logo_url} className='block col-12' alt={organization.name}/>
          </div>
        )}
      </>
    )}
    {organization.branding && organization.branding.show_company_name && (
      <h4 data-testid='org-name' className='truncate mb2'>
        {organization.name}
      </h4>
    )}
    {organization.website_url && (
      <div>
        <span className='block muted'>Official Site</span>
        <div className='flex items-center mb2 mt1'>
          <div className={`link-wrap mr2 flex items-center justify-center`}>
            <a
              href={parseURL(organization.website_url)}
              target='_blank'
              className={`link flex items-center justify-center social-link`}
              rel='noopener noreferrer'>
              <span className='material-icons social-link'>public</span>
            </a>
          </div>
        </div>
      </div>
    )}
    {showSocialFeed(organization).show && (
      <div>
        <span className='block muted social-link'>Social Media</span>
        <div className='flex items-center mb2 mt1'>
          {showSocialFeed(organization).list.map((feed, i) => (
            <div className={`link-wrap mr2 flex items-center justify-center`} key={i}>
              <a
                href={parseURL(feed.to)}
                target='_blank'
                className={`link flex items-center justify-center social-link`}
                rel='noopener noreferrer'>
                {feed.icon}
              </a>
            </div>
          ))}
        </div>
      </div>
    )}
    {organization.about && (
      <div>
        <span className='muted block'>About</span>
        <p data-testid='org-about'>{organization.about}</p>
      </div>
    )}
    {preview && (
      <div>
        <a
          target='_blank'
          className='inline-block'
          href='https://accounts.cinesend.com/settings/organization'
          rel='noopener noreferrer'>
          <button className='cs-button flex items-center white small mt2'>Edit Company Info</button>
        </a>
      </div>
    )}
  </Wrap>
)

const mapStateToProps = state => ({
  organization: state.organization,
  preview: state.utils.is_preview
})

Info.propTypes = {
  organization: PropTypes.object.isRequired,
  preview: PropTypes.bool.isRequired
}

export default connect(
  mapStateToProps,
  null
)(Info)
