import React from "react"
import styled, { keyframes } from "react-emotion"

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
`

const StyledSpinner = styled("div")`
  animation: ${spin} 3s linear infinite;
  font-size: 64px;
`

const Spinner = () => <StyledSpinner className='material-icons block center'>cached</StyledSpinner>

export default Spinner
