import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import bg from "../../public/background_blur.png"
import { checkPassword, hideGate } from "../../api/utils"
import styled from '@emotion/styled'

const GateContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-color: #111;

  h2 {
    color: white !important;
  }
`

const Password = styled.div`
  color: #222 !important;

  input, ::placeholder {
    color: #424A50 !important;
  }
`

const Image = styled.img`
  width: 100%;
`

class Gate extends Component {
  constructor() {
    super()
    this.state = {
      password: ""
    }
  }
  render() {
    return (
      <GateContainer
        className={`white block sm-flex items-center justify-center overflow-auto`}
        style={{ backgroundImage: `url(${bg})` }}>
        <section className='py4 center px3'>
          {this.props.organization && this.props.organization.logo_url && (
            <div
              style={{ height: "140px", width: "140px" }}
              className='bg-white rounded p2 mx-auto mb2 flex items-center justify-center'>
              <Image src={this.props.organization.logo_url} alt={this.props.organization.name}/>
            </div>
          )}
          <h2 data-testid='gate-title'>{this.props.gateTitle}</h2>
          {this.props.isScreenerGroup && (
            <p data-testid='gate-screener-room-name' className='mt0'>
              Screener room by <strong>{this.props.organization.name}</strong>
            </p>
          )}
          {this.props.isPasswordProtected ? (
            <div className='max-width-2 mx-auto pt2'>
              <p className='mt0'>
                A password is required to access this screener{this.props.isScreenerGroup ? " room" : ""}. If you forgot
                your password or did not receive one, please contact{" "}
                {this.props.organization && this.props.organization.name
                  ? this.props.organization.name
                  : "the rightsholder"}
                .
              </p>
              <form>
                <Password className={`block sm-flex py2 max-width-1 mx-auto`}>
                  <input
                    type='password'
                    autoComplete='screener-password'
                    className={`cs-input flex-auto col-12 block sm-col-8`}
                    data-testid='gate-input'
                    placeholder='Enter password'
                    autoFocus={true}
                    value={this.state.password}
                    disabled={this.props.checkingPassword}
                    onChange={e => this.setState({ password: e.target.value })}/>
                  <button
                    data-testid='gate-button'
                    className='cs-button ml0 sm-ml1 white mt2 sm-mt0 col-12 sm-col-4'
                    disabled={!this.state.password.length || this.props.checkingPassword}
                    onClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                      this.props.checkPassword(this.props.sharedScreener._id, this.state.password)
                    }}>
                    {this.props.checkingPassword ? "Checking..." : "Enter"}
                  </button>
                </Password>
              </form>

              {this.props.passwordError && (
                <div>
                  <span data-testid='gate-err-msg' className='red'>
                    Incorrect Password.
                  </span>
                </div>
              )}
            </div>
          ) : (
            <div className='py2'>
              <button className='cs-button ml1 white' onClick={this.props.hideGate}>
                Enter
              </button>
            </div>
          )}
        </section>
      </GateContainer>
    )
  }
}

Gate.propTypes = {
  organization: PropTypes.object,
  gateTitle: PropTypes.string,
  isScreenerGroup: PropTypes.bool,
  isPasswordProtected: PropTypes.bool,
  checkingPassword: PropTypes.bool,
  checkPassword: PropTypes.func,
  hideGate: PropTypes.func,
  passwordError: PropTypes.bool
}

function mapStateToProps(state) {
  return {
    sharedScreener: state.sharedScreener,
    organization: state.organization,
    isPasswordProtected: state.utils.is_password_protected,
    passwordError: state.utils.passwordError,
    checkingPassword: state.utils.checkingPassword,
    gateTitle: state.utils.gate_title,
    isScreenerGroup: state.utils.is_screener_group
  }
}

function mapDispatchToProps(dispatch) {
  return {
    checkPassword: bindActionCreators(checkPassword, dispatch),
    hideGate: bindActionCreators(hideGate, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Gate)
