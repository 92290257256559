import { combineReducers } from "redux"
import { connectRouter } from 'connected-react-router'

import sharedScreener from './modules/shared_screener'
import watermark from "./modules/watermark"
import organization from "./modules/organization"
import projects from "./modules/projects"
import utils from "./modules/utils"

const createRootReducer = (history) =>  combineReducers({
  router: connectRouter(history),
  sharedScreener,
  watermark,
  organization,
  projects,
  utils,
})

export default createRootReducer
