import {
  GET_INITIAL_STATE_FULFILLED,
  GET_INITIAL_STATE_FAILED,
  CHECK_PASSWORD_FULFILLED
} from "@actions"

const initState = {
  status: 'PENDING'
}

export default function reducer(state = initState, action = {}) {
  switch (action.type) {
    case GET_INITIAL_STATE_FULFILLED:
      return {
        status: action.payload.status ? action.payload.status : "READY",
        available_at: action.payload.available_at ?? null,
        extension_requested_at: action.payload.extension_requested_at,
        list: action.payload.projects
      }

    case CHECK_PASSWORD_FULFILLED:
      return {
        status: "READY",
        list: action.payload.projects
      }

    case GET_INITIAL_STATE_FAILED:
      const error = action.payload.status
      return error === 403 ? {
        status: "INCOMPATIBLE_DEVICE",
        recommendation: action.payload.message
      } : {
        status: error === 404 ? "NOT_FOUND" : error === 401 ? "EXPIRED" : "ERROR"
      }
    default:
      return state
  }
}
