import { GET_INITIAL_STATE, CREATE_LOG, CHECK_PASSWORD, HIDE_GATE, HIDE_TERMS } from "@actions"
import { get, post } from './fetch'

export const getInitialState = (sharedScreenerID) => dispatch => 
  dispatch(
    get(
      GET_INITIAL_STATE,
      `sharedScreeners/${sharedScreenerID}`
    )
  )

export const checkPassword = (sharedScreenerID, password) => dispatch => 
  dispatch(
    post(
      CHECK_PASSWORD,
      `sharedScreeners/${sharedScreenerID}/password`,
      {
        password
      }
    )
  )

export const hideGate = () => dispatch => dispatch({ type: HIDE_GATE })

export const hideTerms = () => dispatch => dispatch({ type: HIDE_TERMS })

export const createLog = (sessionLogsURL, log) => dispatch =>
  fetch(sessionLogsURL, {
    method: 'POST',
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(log)
  })

export const downloadHTTP = (url, fileID, fileType, projectID = null) => dispatch => 
  dispatch(
    get(
      'DOWNLOAD',
      `s3/download?url=${url}&projectID=${projectID}&fileID=${fileID}&fileType=${fileType}`,
      res => {
        const link = document.createElement("a")
        link.style.display = "none"
        link.href = res.token
        link.download = "true"
        if (typeof link.download === "undefined") {
          link.setAttribute("target", "_blank")
        }
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    )
  )
