import React from "react"
import { BrowserRouter, Route, Switch } from "react-router-dom"
import Home from '../containers/Home'
import NotFound from '../containers/Errors/404'

const Routes = () => 
  <BrowserRouter>
    <Switch>
      <Route path='/preview/:sharedScreenerID' component={Home}/>
      <Route path='/view/:sharedScreenerID' component={Home}/>
      <Route path='*' component={NotFound} status={404}/>
    </Switch>
  </BrowserRouter>

export default Routes
