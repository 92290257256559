import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import Icon from "./icons"
import Back from "./back"

const allowed = userDevice => {
  const browser = userDevice.browser.name ? userDevice.browser.name.toLowerCase() : ""
  const browserVersion = userDevice.browser.version || ""
  const platform = userDevice.os.name ? userDevice.os.name.toLowerCase() : ""
  const platformVersion = userDevice.os.version ? userDevice.os.version.split("_").join(".") : ""
  const supportedBrowsers = ["chrome", "firefox", "safari", "edge", "opera"]

  // Safari < 9 has DRM playback issues
  if (browser === "safari" && parseFloat(browserVersion.split(".")[0]) < 9) {
    return {
      valid: false,
      message: `Your current Safari version (${browserVersion}) does not support encrypted content. Please upgrade to a newer version.`
    }
  }
  // Firefox < 48 has DRM playback issues
  else if (browser === "firefox" && parseFloat(browserVersion.split(".")[0]) < 48) {
    return {
      valid: false,
      message: `Your current Firefox version (${browserVersion}) does not support encrypted content. Please upgrade to a newer version.`
    }
  }
  // Chrome is the only browser that plays DRM on android
  else if (platform.includes("android") && browser !== "chrome") {
    return {
      valid: false,
      message: `${browser} does not support encrypted content on Android. Please switch to Chrome.`
    }
  }
  // DRM cannot be played on iOS with chrome or Firefox
  // else if (platform.includes("ios") && (browser.includes("chrome") || browser.includes("firefox"))) {
  //   return {
  //     valid: false,
  //     message: `${browser} does not support encrypted content on iOS. Please switch to Safari.`
  //   }
  // }
  // iOS < 11 cannot play DRM content
  else if (platform.includes("ios") && parseFloat(platformVersion.split(".")[0]) <= 11) {
    return {
      valid: false,
      message: `iOS ${platformVersion} cannot play encrypted content. Please upgrade your iOS version.`
    }
  }
  // iOS < 11.2.1 cannot play DRM content
  else if (
    platform.includes("ios") &&
    (parseFloat(platformVersion.split(".")[0]) < 12 && parseFloat(platformVersion.split(".")[0]) >= 11) &&
    (parseFloat(platformVersion.split(".")[1]) && parseFloat(platformVersion.split(".")[1]) < 3) &&
    (parseFloat(platformVersion.split(".")[2]) && parseFloat(platformVersion.split(".")[2]) < 2)
  ) {
    return {
      valid: false,
      message: `iOS ${platformVersion} cannot play encrypted content. Please upgrade your iOS version.`
    }
  }
  // We only support the major browsers for DRM play
  else if (!supportedBrowsers.includes(browser)) {
    return {
      valid: false,
      message:
        "Your current browser is not supported. Supported browsers include Chrome, Firefox, Safari, Opera and Edge."
    }
  } else return { valid: true }
}

const Device = ({ children, device }) => {
  const opts = allowed(device)
  return opts.valid ? (
    children
  ) : (
    <section style={{ height: '100vh' }} className='flex items-center justify-center'>
      <div className='center'>
        <img src={Icon} alt='lock icon' icon='lock'/>
        <h3>Device Error</h3>
        <p className='my1'>
          {opts.message || "A security vulnerability in this device is preventing this video from loading."}
        </p>
        <Back/>
      </div>
    </section>
  )
}

Device.propTypes = {
  children: PropTypes.node.isRequired,
  device: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  device: state.utils.browser
})

export default connect(
  mapStateToProps,
  null
)(Device)
