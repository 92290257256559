import React from 'react'
import Genres from './genres'
import Thumbnail from './thumbnail'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { downloadHTTP } from '@api/utils'
import Poster from './poster'
import RequestExtension from './request_extension'

class SingleProject extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      logline: {
        text: props.project.project_logline,
        needsReadMore: props.project.project_logline &&
          props.project.project_logline.length > 200,
        readMore: false,
      },
    }
  }

  render () {
    const { project, availableAt, expiresAt } = this.props
    let accessible = true
    if (availableAt && dayjs.utc(availableAt).isAfter(dayjs())) {
      accessible = false
    }
    if (expiresAt && dayjs.utc(expiresAt).isBefore(dayjs())) {
      accessible = false
    }

    return (
      <div className={`flex flex-wrap rounded col-12`}>
        <div className={`col-3`}>
          <Poster src={project.poster} active={true}/>
        </div>
        <div className={`col-9 px3`}>
          <p className={`logline mb0 body-font`}>
            {this.state.logline.needsReadMore && !this.state.logline.readMore
              ? `${this.state.logline.text.slice(0, 190)}... `
              : this.state.logline.text}
            {this.state.logline.needsReadMore && !this.state.logline.readMore &&
            (
              <button
                onClick={() =>
                  this.setState({
                    logline: { ...this.state.logline, readMore: true },
                  })
                }
                style={{ height: 'auto', color: 'white' }}
                className="cs-button link mb2 body-font">
                <u>Read More</u>
              </button>
            )}
          </p>
          <Genres genres={project.project_genres}/>
          {project.project_custom_fields.map(field => (
            <div key={field._id} className="mt1 body-font">
              <strong>{field.title}: </strong> {field.value}
            </div>
          ))}
          <div className='pt2'>
            {this.props.availability}
            <RequestExtension sharedScreenerID={this.props.sharedScreenerID} isExpired={this.props.isExpired}/>
          </div>
          <div className="flex flex-wrap col-12 mt2 pt1">
            {project.screeners.map(screener => (
              <div className={'col-12 lg-col-6 xl-col-4'} key={screener._id}>
                <Thumbnail dateAccessible={accessible} project={project}
                           screener={screener}/>
              </div>
            ))}
          </div>
          {project.screener_attachments.length > 0 && (
            <div className="truncate">
              <div className="flex flex-wrap border-top border-gray-5 col-12 mt2 pt1"/>
              {project.screener_attachments.map(attachment => (
                <div key={attachment._id}>
                  <button
                    onClick={() => {
                      this.props.downloadHTTP(attachment.url, attachment._id,
                        attachment.type, attachment.project_id)
                    }}
                    className="cs-button link flex items-center my0"
                    style={{
                      fontSize: '0.9em',
                      height: '25px',
                      color: 'white'
                    }}>
                    <i
                      className="material-icons mr1"
                      style={{
                        transform: 'rotate(45deg)',
                        fontSize: '0.85em',
                      }}>
                      attach_file
                    </i>
                    <span>{attachment.name}</span>
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    )
  }
}

SingleProject.propTypes = {
  project: PropTypes.object.isRequired,
}

const mapDispatchToProps = dispatch => ({
  downloadHTTP: bindActionCreators(downloadHTTP, dispatch),
})

export default connect(null, mapDispatchToProps)(SingleProject)
