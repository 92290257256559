import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { connect } from "react-redux"

const Back = ({ isScreenerGroup, sharedScreenerID }) =>
  isScreenerGroup ? (
    <Link className='block mt2' to={`/view/${sharedScreenerID}`}>
      <button className='cs-button white small'>Back to All Screeners</button>
    </Link>
  ) : null

Back.propTypes = {
  isScreenerGroup: PropTypes.bool.isRequired,
  sharedScreenerID: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  sharedScreenerID: state.utils.shared_screener_id,
  isScreenerGroup: state.utils.is_screener_group
})

export default connect(
  mapStateToProps,
  null
)(Back)
