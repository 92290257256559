export const GET_INITIAL_STATE = "GET_INITIAL_STATE"
export const GET_INITIAL_STATE_PENDING = "GET_INITIAL_STATE_PENDING"
export const GET_INITIAL_STATE_FAILED = "GET_INITIAL_STATE_REJECTED"
export const GET_INITIAL_STATE_FULFILLED = "GET_INITIAL_STATE_FULFILLED"

export const UPDATE_UTILS = "UPDATE_UTILS"

export const CREATE_LOG = "CREATE_LOG"

export const HIDE_GATE = "HIDE_GATE"
export const HIDE_TERMS = "HIDE_TERMS"

export const CHECK_PASSWORD = "CHECK_PASSWORD"
export const CHECK_PASSWORD_FULFILLED = "CHECK_PASSWORD_FULFILLED"
export const CHECK_PASSWORD_FAILED = "CHECK_PASSWORD_REJECTED"
export const CHECK_PASSWORD_PENDING = "CHECK_PASSWORD_PENDING"
