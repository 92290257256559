import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import './index.css'
import './normalize.css'
import App from './app';
import * as serviceWorker from './serviceWorker';
import { ConnectedRouter } from 'connected-react-router';
import configureStore, { history } from './redux/store'
import dayjs from "dayjs"
import dayjsPluginUTC from "dayjs-plugin-utc"
dayjs.extend(dayjsPluginUTC)

const store = configureStore()

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App/>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
