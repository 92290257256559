import React from "react"
import { bindActionCreators } from 'redux'
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter } from 'react-router-dom'
import { Status } from "@bitcine/cs-theme"
import CineSendPlayer from "@bitcine/cinesend-player"
import PiracyDetector from "@bitcine/piracy-detector"
import { Helmet } from "react-helmet"
import { get } from '@api/fetch'
import { createLog } from '@api/utils'
import Encoded from "./utils/encoded"
import Device from "./utils/device"
import Piracy from "./utils/piracy"
import Wrap from "./utils/wrap"
import PlaybackError from "./utils/playback_error"

class Player extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      player: null,
      piracy_detected: false,
      has_watermark: !!props.watermark._id,
      endpoint_status: "PENDING",
      endpoints: {
        videos: [],
        preroll: []
      },
      device: {},
      source: null,
      playback_error: false
    }
    this.playerID = "cs-bt-player"
  }
  componentDidMount() {
    this.getEndpoints()
  }
  getEndpoints = () => {
    this.props.get(
      'GET_ENDPOINTS',
      `sharedScreeners/${this.props.sharedScreenerID}/screeners/${this.props.screener._id}/endpoints`,
      res => {
        // if (this.state.has_watermark && res.data?.device?.browser?.name === 'Firefox') {
        //   this.setState({
        //     endpoint_status: "ERROR",
        //     playback_error: "Please use a different browser. Watermarked content is not currently supported by this browser."
        //   })
        //   return
        // }
        this.setState({
          endpoint_status: "READY",
          endpoints: res.data.endpoints,
          source: res.data.endpoints.videos[0].source,
          device: res.data.device,
          config: res.data.config
        })
      },
      err => {
        this.setState({
          playback_error: err.message,
          endpoint_status: "ERROR"
        })
      }
    )
  }
  onPlayerEvent = log => {
    this.props.createLog(this.state.config.session_logs_url, {
      ...log,
      videoID: this.state.source.video_id,
      sessionID: this.state.source.session_id,
      timestamp: Date.now()
    })
    if (log.logType === 'media_error') {
      this.setState({
        playback_error: log.error
      })
    }
    else {
      this.setState({
        playback_started: true
      })
    }
  }
  onPiracyDetection = (code, message, info) => {
    this.setState({
      piracy_detector_enabled: false,
      piracy_detected: true
    })
  }
  shouldDisableFullScreen = () => {
    if (!this.state.has_watermark) return false
    // If there is no watermark we dont need to disable fullscreen
    // If iOS and there is a watermark we need to disable full screen because the watermark will not show in fullscreen mode
    const os = this.state.device?.os?.name ? this.state.device?.os?.name.toLowerCase() : ""
    return os.includes("ios")
  }
  getRefreshURL = () => {
    if (typeof window === "undefined") return ""
    else return window.location.origin + window.location.pathname
  }
  render() {
    return (
      <Wrap>
        <Helmet>
          <title>{this.props.screener.project_title}</title>
        </Helmet>
        <Status
          height={typeof window === "undefined" ? 400 : window.innerHeight}
          pending={this.state.endpoint_status === "PENDING"}
          error={this.state.endpoint_status === "ERROR"}
          errorMessage={this.state.playback_error}>
          <Encoded encoded={!this.state.endpoints.is_pending_encode}>
            <Device>
              <PlaybackError error={this.state.playback_error}>
                <Piracy piracy={false}>
                  <PiracyDetector
                    enabled={this.state.has_watermark && this.state.playback_started && this.state.device?.browser?.name !== 'Firefox'}
                    watermark={this.props.watermark}
                    onPiracyDetection={this.onPiracyDetection}
                    appendTo={`#bitmovinplayer-video-${this.playerID}`}
                    mutationURL={`/api/sharedScreeners/${this.props.sharedScreenerID}/watermarkmutation`}
                    refreshURL={this.getRefreshURL()}>
                    <CineSendPlayer
                      playerCode={'bitmovin'}
                      playerID={this.playerID.toString()}
                      debug={process.env.NODE_ENV !== "production"}
                      endpoints={this.state.endpoints}
                      config={this.state.config}
                      fillViewport={true}
                      disableFullScreen={this.shouldDisableFullScreen()}
                      showVideoQualitySelector={true}
                      activeIndex={0}
                      autoPlay={true}
                      onPlayerEvent={log => this.onPlayerEvent(log)}
                      onVideosComplete={() => {
                        // Delay here so that media_end log can be fired from inside player
                        setTimeout(() => this.props.history.push(`/view/${this.props.sharedScreenerID}`), 500)
                      }}/>
                  </PiracyDetector>
                </Piracy>
              </PlaybackError>
            </Device>
          </Encoded>
        </Status>
      </Wrap>
    )
  }
}

Player.defaultProps = {
  watermark: {},
  email: "",
  name: ""
}

Player.propTypes = {
  screener: PropTypes.object.isRequired,
  ip: PropTypes.string.isRequired,
  browser: PropTypes.object.isRequired,
  isScreenerGroup: PropTypes.bool.isRequired,
  watermark: PropTypes.object,
  isPreview: PropTypes.bool.isRequired,
  email: PropTypes.string,
  name: PropTypes.string,
  sharedScreenerID: PropTypes.string.isRequired
}

const mapStateToProps = (state, ownProps) => ({
  sharedScreenerID: state.utils.shared_screener_id,
  browser: state.utils.browser,
  ip: state.utils.ip,
  isScreenerGroup: state.utils.is_screener_group,
  watermark: state.watermark,
  email: state.utils.email,
  name: state.utils.name,
  isPreview: state.utils.is_preview,
  screener: Array.prototype.concat
    .apply(
      [],
      state.projects.list.map(p => p.screeners)
    )
    .find(s => s._id === ownProps.screenerID)
})

const mapDispatchToProps = dispatch => ({
  get: bindActionCreators(get, dispatch),
  createLog: bindActionCreators(createLog, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Player))
