import React from "react"
import Icon from "@src/public/search.inline.svg"

const Empty = ({ projects }) => 
  <div className='py4 center col-12'>
    <img
      src={Icon}
      style={{
        width: "150px",
        height: "120px",
        marginBottom: "-20px",
        filter: "grayscale(100%)"
      }}
      alt='No Screeners'/>
    <h4 className='mb0 body-text-font-color'>No Screeners Found</h4>
    {projects.length === 0 ? (
      <p className='body-text-font-color'>
        This screener room contains no screeners. Please contact your screener room owner for more details.
      </p>
    ) : (
      <p className='body-text-font-color'>Update your search or filters to find screeners</p>
    )}
  </div>

export default Empty
