export const filter = (allScreeners = [], search, { by, direction }, genres) => {
  let screeners = allScreeners
    .filter(s => stringify(s.project_title || "").includes(stringify(search)))
    .filter(s => includesFilter(s, genres))

  if (by && direction) {
    screeners.sort((a, b) => {
      if (by === "title") {
        const p1 = a.project_title || ""
        const p2 = b.project_title || ""
        if (direction === "DESC") {
          return p1.trim().localeCompare(p2.trim())
        }
        else {
          return p2.trim().localeCompare(p1.trim())
        }
      }
      return false
    })
  }

  return screeners
}

const stringify = string => {
  const s = string || ""
  return s.toLowerCase().trim()
}

const includesFilter = (screener, genres) => {
  if (genres.length === 0) return true
  if (!screener.project_genres || screener.project_genres.length === 0) return false

  const stringGenres = JSON.stringify(genres)
  let projectGenres = screener.project_genres || ""

  if (Array.isArray(projectGenres)) {
    projectGenres = projectGenres.map(pg => pg.value).join(",")
  }

  return projectGenres.split(",").filter(projectGenre => stringGenres.includes(projectGenre.trim())).length > 0
}
