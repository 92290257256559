import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import styled from "react-emotion"

const StyledWrap = styled("div")`
  width: 100vw;
  height: 100vh;
  background: #000000;
  .close {
    display: none;
    opacity: 0;
    font-size: 32px;
    z-index: 1000;
  }
  :hover {
    .close {
      display: inline-block;
      opacity: 1;
    }
  }
  .bmpui-ui-titlebar {
    padding-left: ${props => (props.isScreenerGroup ? "4em" : "1em")} !important;
  }
`

class Wrap extends React.Component {
  componentDidMount() {
    window.addEventListener("keyup", this.onKeyUp, false)
    window.addEventListener("scroll", this.onScroll, false)
  }
  componentWillUnmount() {
    window.removeEventListener("keyup", this.onKeyUp, false)
    window.removeEventListener("scroll", this.onScroll, false)
  }
  onScroll = e => {
    window.scrollTo(0, 0)
  }
  onKeyUp = e => {
    if (e.keyCode === 27 && this.props.isScreenerGroup) {
      this.props.history.push(`/view/${this.props.sharedScreenerID}`)
    }
  }
  onContextMenu = e => {
    e.preventDefault()
    e.stopPropagation()
  }
  render() {
    return (
      <StyledWrap isScreenerGroup={this.props.isScreenerGroup} onContextMenu={this.onContextMenu}>
        {this.props.isScreenerGroup && (
          <Link to={`/view/${this.props.sharedScreenerID}`}>
            <button className='link mt1 ml1 material-icons fixed top-0 left-0 close'>
              <span className='white'>close</span>
            </button>
          </Link>
        )}
        {this.props.children}
      </StyledWrap>
    )
  }
}

Wrap.propTypes = {
  children: PropTypes.node.isRequired,
  isScreenerGroup: PropTypes.bool.isRequired,
  sharedScreenerID: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  sharedScreenerID: state.utils.shared_screener_id,
  isScreenerGroup: state.utils.is_screener_group
})

export default withRouter(connect(mapStateToProps, null)(Wrap))
