import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import dayjs from 'dayjs'
import ReactTooltip from 'react-tooltip'
import styled from '@emotion/styled'
import { FILTER_OPTIONS } from '@constants'
import { filter } from './filter'
import Project from './project'
import Empty from './empty'
import SearchAndFilter from './search_and_filter'
import RequestExtension from './request_extension'
import SingleProject from './single_project'

const Wrapper = styled.div`
  .filterRow {
    user-select: none;
    &:hover {
      background: #ededed;
    }
  }

  .poster {
    transform: scale3d(1, 1, 1);
    transition: transform .3s linear;
    cursor: pointer;
  }

  .project {
    cursor: pointer;
    transform: scale(1);
    transition: transform 100ms ease-in-out;
    &:hover {
      transform: scale(1.02);
    }
  }

  .projectActive {
    transform: scale(1.02) !important;
  }

  .closeBtn {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    &:hover {
      background: #ededed;
    }
  }
  .clearSearchIcon {
    border-radius: 50%;
    width: 26px;
    height: 26px;
    &:hover {
      background: #ffffff;
      * { color: black }
    }
  }

  .activeButton {
    background: #ffffff !important;
    * { color: #424A50; }
  }

  .logline {
    transition: all .3s linear;
  }

  .popover {
    margin-top: -2px;
    z-index: 100;
    background: white;
    border-radius: 3px;
    opacity: 0;
    transform: scale(0.7);
    animation: popover .3s linear forwards;
    * { color: #424A50 !important; }

    .body-text {
      * { color: #424A50 !important; }
    }

    .white {
      color: white !important;
    }
    .red {
      color: #e00000 !important;
    }
  }

  @keyframes popover{
    0%{
      opacity: 0;
      transform: scale(0.95);
    }
    100%{
      opacity: 1;
      transform: scale(1)
    }
  }

`

class Screeners extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      search: '',
      sort: {
        by: 'title',
        direction: null,
      },
      filters: [],
      activeFilter: '',
      activeProjectID: '',
      activeProject: {},
    }
  }

  toggleFilters (filter) {
    if (this.state.filters.includes(filter)) {
      this.setState({
        filters: this.state.filters.filter(f => f !== filter),
      })
    } else {
      this.setState({
        filters: [...this.state.filters, filter],
      })
    }
  }

  toggleAll () {
    if (this.state.filters.length === 0) {
      this.setState({ filters: FILTER_OPTIONS })
    } else {
      this.setState({ filters: [] })
    }
  }

  changeActiveScreener (direction) {
    const ids = []
    const activeProjectID = this.state.activeProjectID
    filter(this.props.projects, this.state.search, this.state.sort,
      this.state.filters).forEach(s => ids.push(s._id))
    const index = ids.indexOf(activeProjectID)
    let newIndex = direction === 'next' ? index + 1 : index - 1
    if (newIndex > ids.length - 1) newIndex = 0
    else if (newIndex < 0) newIndex = ids.length - 1
    setTimeout(() => {
      this.setState({ activeProjectID: ids[newIndex] })
    }, 50)
  }

  formatDate (date) {
    return dayjs.utc(date).local().format('MMM D, YYYY h:mma')
  }

  setActiveProject (activeProjectID) {
    let activeProject = this.props.projects.find(
      project => project._id === activeProjectID)
    this.setState({ activeProjectID, activeProject })
  }

  unsetActiveProject () {
    this.setState({ activeProjectID: null, activeProject: {} })
  }

  isSingleMode () {
    return !this.state.activeProjectID && this.props.projects.length === 1
  }

  render () {
    const {
      projects,
      name,
      organization,
      availableAt,
      expiresAt,
      isExpired,
    } = this.props
    const projectsList = filter(projects, this.state.search, this.state.sort,
      this.state.filters)
    const logoURL = organization.branding
      ? organization.branding.screener_logo_url
      : organization.logo_url
    const availability = isExpired
      ? <b>This content has expired.</b>
      : availableAt && expiresAt
        ? <span>This content is available to watch from <b>{this.formatDate(
          availableAt)}</b> until <b>{this.formatDate(expiresAt)}</b>.</span>
        : availableAt
          ?
          <span>This content is available to watch starting <b>{this.formatDate(
            availableAt)}</b>.</span>
          : expiresAt
            ?
            <span>This content is available to watch until <b>{this.formatDate(
              expiresAt)}</b>.</span>
            : null
    return (
      <Wrapper className="p3 flex-auto">
        <div className="block md-hide lg-hide xl-hide center py3 px2">
          {logoURL && (
            <div
              style={{ width: '100px', height: '100px' }}
              className={`${
                organization.branding ? 'sidebar-background-color' : 'bg-white'
              } rounded p1 flex items-center mx-auto mb3`}>
              <img src={logoURL} className="block col-12"
                   alt={organization.name}/>
            </div>
          )}
          <h4 className="header-text-color">{name}</h4>
        </div>
        <div
          className="block md-flex lg-flex xl-flex items-center border-bottom border-gray-2 pb2 mb2">
          {!this.state.activeProjectID && <h2 className="mb0 flex-auto xs-hide sm-hide md-block lg-block xl-block header-text-color">{name}</h2>}
          {this.state.activeProjectID && <>
            <div className='flex items-center'>
              <ReactTooltip
                id={'back-arrow-tip'}
                place="bottom"
                effect="solid"
                className="bold"/>
              <button
                data-tip={`Back to ${name}`}
                data-for={'back-arrow-tip'}
                style={{ color: 'white', fontSize: '28px '}}
                className="cs-button link pointer material-icons"
                onClick={() => this.unsetActiveProject()}>
                navigate_before
              </button>
              <h2 className="ml2 mb0 flex-auto xs-hide sm-hide md-block lg-block xl-block header-text-color">{this.state.activeProject.project_title}
                {this.state.activeProject.project_release_year &&
                <span> ({this.state.activeProject.project_release_year})</span>}
              </h2>
            </div>
          </>}
        </div>
        <div className="flex items-center justify-between mb2">
          {!this.state.activeProjectID &&
          <div>
            {!this.isSingleMode() &&
            <div className="mr2 xs-hide sm-hide">
              {availability}
              <RequestExtension sharedScreenerID={this.props.sharedScreenerID}
                                isExpired={isExpired}/>
            </div>}
          </div>
          }
          {!this.state.activeProjectID && projects.length > 1 &&
          <SearchAndFilter
            sort={this.state.sort}
            setSort={sort => this.setState({ sort })}
            search={this.state.search}
            setSearch={search => this.setState({ search })}
            filters={this.state.filters}
            activeFilter={this.state.activeFilter}
            setActiveFilter={activeFilter => this.setState({ activeFilter })}
            toggleAll={this.toggleAll.bind(this)}
            toggleFilters={this.toggleFilters.bind(this)}/>}
        </div>
        {this.state.activeProjectID && <>
          <SingleProject project={this.state.activeProject}
                         availableAt={availableAt} expiresAt={expiresAt}
                         availability={availability}/>
        </>}
        {!this.state.activeProjectID && projects.length > 1 &&
        <div style={{ borderColor: '#404755' }}
             className="block col-12 md-hide lg-hide xl-hide border-top mb2"/>
        }
        <div className="flex flex-wrap" data-testid="room-screeners">
          {!this.state.activeProjectID && projectsList.length === 0 &&
          <Empty projects={projects}/>}
          {!this.state.activeProjectID && projectsList.length === 1 &&
          <SingleProject project={projectsList[0]} availableAt={availableAt}
                         expiresAt={expiresAt} availability={availability}/>}
          {!this.state.activeProjectID && projectsList.length > 1 &&
          projectsList.map(project => (
            <Project
              project={project}
              availableAt={availableAt}
              expiresAt={expiresAt}
              activeProjectID={this.state.activeProjectID}
              setActiveProject={activeProjectID => this.setActiveProject(
                activeProjectID)}
              changeActiveScreener={direction => this.changeActiveScreener(
                direction)}
              key={project._id}/>
          ))}
        </div>
      </Wrapper>
    )
  }
}

const mapStateToProps = state => ({
  projects: state.projects.list,
  organization: state.organization,
  name: state.sharedScreener.name,
  availableAt: state.sharedScreener.available_at,
  expiresAt: state.sharedScreener.expires_at,
  isExpired: state.sharedScreener.is_expired,
  sharedScreenerID: state.sharedScreener._id,
})

Screeners.propTypes = {
  projects: PropTypes.array.isRequired,
  name: PropTypes.string,
  organization: PropTypes.object.isRequired,
  availableAt: PropTypes.string,
  expiresAt: PropTypes.string,
  isExpired: PropTypes.bool.isRequired,
  sharedScreenerID: PropTypes.string.isRequired,
}

export default connect(mapStateToProps, null)(Screeners)
