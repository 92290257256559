import React from "react"
import PropTypes from "prop-types"
import Poster from "./poster"

class Project extends React.Component {
  constructor(props) {
    super(props)
    this.state = { open: true }
    this.tab = this.tab.bind(this)
    this.onClick = this.onClick.bind(this)
  }
  componentDidMount() {
    window.addEventListener("keyup", this.tab, false)
  }
  componentWillUnmount() {
    window.removeEventListener("keyup", this.tab, false)
  }
  tab(e) {
    const nextKeys = [9, 39]
    const prevKeys = [37]
    if (this.props.activeProjectID === this.props.project._id) {
      if (nextKeys.includes(e.keyCode)) {
        this.props.changeActiveScreener("next")
      } else if (prevKeys.includes(e.keyCode)) {
        this.props.changeActiveScreener("prev")
      }
    }
  }
  onClick(e) {
    e.preventDefault()
    e.stopPropagation()
    this.setActiveProject(this.props.project._id)
  }
  setActiveProject(projectID) {
    this.props.setActiveProject(projectID)
  }
  render() {
    const { project, activeProjectID } = this.props
    const isBlurred = activeProjectID && activeProjectID !== project._id
    return (
      <div
        style={{
          opacity: isBlurred ? 0.15 : 1,
          transition: "opacity 0.1s linear"
        }}
        id={`screener-card-${project._id}`}
        data-testid={`room-screener-${project._id}`}
        onClick={this.onClick}
        className={`relative col-12 xs-col-4 sm-col-4 md-col-4 lg-col-3 xl-col-2`}>
        <div
          className='flex sm-block md-block lg-block xl-block items-center px1 mb3'>
          <div
            className={`col-3 sm-col-12 project ${
              activeProjectID === project._id ? 'projectActive' : ""
            }`}>
            <Poster src={project.poster} active={activeProjectID === project._id}/>
          </div>
          <div className='pl2 sm-pl0 col-9 sm-col-12'>
            <strong data-testid={`room-screener-${project._id}-title`} className='truncate mb0 mt1 block body-text'>
              {project.project_title}
            </strong>
          </div>
        </div>
      </div>
    )
  }
}

Project.propTypes = {
  project: PropTypes.object.isRequired,
  availableAt: PropTypes.string,
  expiresAt: PropTypes.string,
  setActiveProject: PropTypes.func.isRequired,
  activeProjectID: PropTypes.string,
  changeActiveScreener: PropTypes.func.isRequired
}

export default Project
