import React from "react"
import PropTypes from "prop-types"
import Icon from "./icons"

class Piracy extends React.Component {
  componentDidUpdate(prevProps) {
    if (!prevProps.piracy && this.props.piracy) {
      //window.location.reload()
    }
  }
  render() {
    const { piracy, children } = this.props
    return !piracy ? (
      children
    ) : (
      <section style={{ height: '100vh', background: 'black '}} className='flex items-center justify-center'>
        <div className='center'>
          <img src={Icon} alt='lock icon' icon='lock'/>
          <h3>Security Error</h3>
          <p className='my1'>
            A security vulnerability has been found in this device.
            <br/>
            Please contact the content owner for more information.
          </p>
          {process.env.NODE_ENV !== "production" && (
            <a href={window.location.origin + window.location.pathname + "?reset_pd=true"}>Reset</a>
          )}
        </div>
      </section>
    )
  }
}

Piracy.propTypes = {
  children: PropTypes.node.isRequired,
  piracy: PropTypes.bool.isRequired
}

export default Piracy
