import React from "react"
import Icon from "./icons/error.inline.svg"
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'

const Incompatible = ({ recommendation }) => (
  <section data-testid='error-page' className='py4 center'>
    <img src={Icon} alt='Error!' style={{ width: "150px", marginBottom: "-1rem" }}/>
    <h3 className='mb0'>Incompatible Device Detected</h3>
    <p className='mt0'>{ReactHtmlParser(recommendation)}</p>
  </section>
)

const mapStateToProps = state => ({
  recommendation: state.projects.recommendation
})

const mapDispatchToProps = dispatch => ({
})

Incompatible.propTypes = {
  recommendation: PropTypes.string
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Incompatible))
