import React from 'react'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import CSSInjector from '../App/CSSInjector'
import Aside from '@src/components/organization/aside'
import Nav from '@src/components/nav/'
import Header from '@src/components/header/'
import Footer from '@src/components/footer/'
import Player from '@src/components/player/'
import Screeners from '@src/components/screeners/index'
import Piracy from '@src/components/player/utils/piracy'
import styled from '@emotion/styled'
import Error from '../Errors/error'
import NotFound from '../Errors/404'
import Incompatible from '../Errors/incompatible'
import Gate from '../../components/gate'
import Terms from '../../components/terms'
import { getInitialState } from '@api/utils'
import { Status } from '@bitcine/cs-theme'

const MainContainer = styled.div`
  ${props => props.showNotice ? "margin-top: 40px;": ""}
`

const ContentContainer = styled.div`
  position: relative;
  min-height: 100vh;
  width: 100%;
`

const ScreenersContainer = styled.div`
  position: relative;
  padding-bottom: 65px;
`

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      redirect: false
    }
  }
  componentDidMount() {
    this.props.getInitialState(this.props.match.params.sharedScreenerID)
  }
  render() {
    const {
      status,
      location: { search },
      showGate,
      showTerms,
      showNotice
    } = this.props

    if (status === 'PENDING') {
      return <Status pending={true}/>
    }
    if (status === "PIRACY") {
      return <Piracy piracy={true}/>
    }
    if (status === "NOT_FOUND") {
      return <NotFound/>
    }
    if (status === "ERROR") {
      return <Error/>
    }
    if (status === "INCOMPATIBLE_DEVICE") {
      return <Incompatible/>
    }
    if (showGate) {
      return <Gate/>
    }
    if (showTerms) {
      return <Terms/>
    }

    const screenerID = queryString.parse(search).watch
    if (screenerID) {
      return <Player screenerID={screenerID}/>
    }

    return (
      <section>
        <Header/>
        <MainContainer className='flex' showNotice={showNotice}>
          <CSSInjector/>
          <Nav/>
          <Aside/>
          <ContentContainer>
            <ScreenersContainer>
              <Screeners/>
            </ScreenersContainer>
            <Footer/>
          </ContentContainer>
        </MainContainer>
      </section>
    )
  }
}

const mapStateToProps = state => ({
  status: state.projects.status,
  date: state.projects.extension_requested_at,
  available_at: state.projects.available_at,
  isScreenerRoom: state.utils.is_screener_group === true,
  projects: state.projects,
  sharedScreener: state.sharedScreener,
  showGate: state.utils.show_gate,
  showTerms: state.utils.show_terms,
  showNotice: state.sharedScreener.watch_on_tv_message
})

const mapDispatchToProps = dispatch => ({
  getInitialState: bindActionCreators(getInitialState, dispatch)
})

Home.propTypes = {
  status: PropTypes.string.isRequired,
  isScreenerRoom: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  showGate: PropTypes.bool.isRequired,
  showTerms: PropTypes.bool.isRequired,
  date: PropTypes.string,
  available_at: PropTypes.string,
  params: PropTypes.object,
  showNotice: PropTypes.string
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home))
