import React from "react"
import PropTypes from "prop-types"
import styled from "react-emotion"
import Spinner from "./icons/spinner"
import Back from "./back"

const Wrap = styled("section")`
  height: 100vh;
`

const Encoded = ({ children, encoded }) =>
  encoded ? (
    children
  ) : (
    <Wrap className='flex items-center justify-center'>
      <div className='center'>
        <Spinner/>
        <h3>Content Pending</h3>
        <p className='my1'>
          This screener is still being encoded with studio-grade DRM technology and will be available soon.
          <br/>
          Please return to this screener at a later time.
        </p>
        <Back/>
      </div>
    </Wrap>
  )

Encoded.propTypes = {
  children: PropTypes.node.isRequired,
  encoded: PropTypes.bool.isRequired
}

export default Encoded
